<template>
    <div class="siteLease_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.businessDomainName"> </CourseTitle>
            <div class="content businessArea">
                <ul class="display">
                    <li @mouseenter="enter(index)" @mouseleave="activeIndex = null" class="businessArea_list display column"
                        v-for="(item, index) in formData.siteLeaseBusinessDomains" :key="index">
                        <img :src="activeIndex == index ? item.highlightIcon : item.icon" alt="" />
                        <div class="name">{{ item.businessDomainName }}</div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.siteIntroduceName"> </CourseTitle>
            <Module8S :introduce="true" :introduceData="introduceData" :dataList="formData.list"
                :imgList="formData.list[0].images"></Module8S>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.trafficGuideName"> </CourseTitle>
            <div class="content trafficGuide flex">
                <div class="fl">
                    <div class="list flex-center" v-for="(item, index) in formData.siteLeaseSiteIntroduces" :key="index">
                        <img :src="item.siteIntroduceImgUrl" alt="" />
                        <div class="name">{{ item.siteIntroduceName }}</div>
                    </div>
                </div>
                <div class="fr">
                    <img :src="formData.trafficGuideImgUrl" alt="" />
                </div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle title="预约免费实地考察"> </CourseTitle>
            <FreeCharge :status="15" :type="'场地租赁'"></FreeCharge>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
import Module8S from '../components/enterpriseService/module8'
import FreeCharge from '@/components/freeCharge'
import 'swiper/css/swiper.css'
export default {
    components: {
        BannerImg,
        CourseTitle,
        FreeCharge,
        Module8S,
    },
    data() {
        return {
            activeIndex: null,
            list: [
                {
                    icon_y: require('./img/siteLease/1.png'),
                    icon_n: require('./img/siteLease/2.png'),
                    name: '教学辅导',
                },
                {
                    icon_y: require('./img/siteLease/1.png'),
                    icon_n: require('./img/siteLease/2.png'),
                    name: '考核测评',
                },
                {
                    icon_y: require('./img/siteLease/1.png'),
                    icon_n: require('./img/siteLease/2.png'),
                    name: '企业培训',
                },
                {
                    icon_y: require('./img/siteLease/1.png'),
                    icon_n: require('./img/siteLease/2.png'),
                    name: '团体会议',
                },
            ],
            formData: {},
            introduceData: '',
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.siteLeaseApi().then((res) => {
                this.formData = res.data
                this.formData.list.forEach((item) => {
                    item.images = this.imgData(item.images, 3)
                })
                this.introduceData = this.formData.list[0].introduce
                this.gejService = this.formData.jobSalutationGolgaServices[0]
            })
        },
        enter(index) {
            this.activeIndex = index
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
        //格式化轮播图数据
        imgData(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }
            }
            return (dataList = [...newDataList])
        },
    },
}
</script>
<style lang="scss" scoped>
.siteLease_page {
    .module1 {
        height: 620px;
        background: #fafafa;

        .businessArea {
            margin-top: 31px;

            ul {
                height: 350px;

                .businessArea_list:last-child {
                    border-right: 1px solid #ddd;
                }

                .businessArea_list:hover {
                    width: 300px;
                    height: 350px;
                    transition: all 0.6s;
                    background: #003c7e !important;

                    .name {
                        color: #fff;
                    }
                }

                .businessArea_list {
                    width: 300px;
                    height: 300px;
                    background: white;
                    border: 1px solid #ddd;
                    border-right: none;
                    transition: all 0.6s;

                    img {
                        width: 88px;
                        height: 88px;
                    }

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 28px;
                    }
                }
            }
        }
    }

    .module2 {
        height: 656px;

        .spaceIntro {
            margin-top: 30px;
        }
    }

    .module3 {
        height: 620px;
        background: #fafafa;

        .trafficGuide {
            width: 1200px;
            height: 359px;
            margin-top: 35px;
            background: #fff;

            .fl {
                width: 650px;
                height: 100%;
                padding-top: 45px;
                padding-left: 45px;
                box-sizing: border-box;

                .list {
                    margin-bottom: 44px;

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    .name {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 48px;
                        margin-left: 16px;
                    }
                }
            }

            .fr {
                img {
                    width: 550px;
                    height: 360px;
                }
            }
        }
    }
}
</style>
<style>
.swiper-button-prev:after,
.swiper-button-next:after {
    content: none;
}
</style>